/* ---------------------------- fonts ---------------------------- */

@font-face {
  font-family: '3dumbregular';
  src: url('../src/fonts/3dumb-webfont.woff2') format('woff2'),
    url('../src/fonts/3dumb-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* ---------------------------- elements ---------------------------- */

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  background: rgb(47, 34, 242);
  background: linear-gradient(
    67deg,
    rgba(47, 34, 242, 1) 0%,
    rgba(242, 62, 46, 1) 69%
  );
  color: white;
  font-family: '3dumbregular';
  font-size: 16px;
  letter-spacing: 3px;
  margin: 0px;
  min-height: 100vh;
  position: relative;
}

button {
  padding: 1rem 2rem;
  color: white;
  max-height: -moz-fit-content;
  max-height: fit-content;
  border-radius: 15px;
  background-color: blueviolet;
  border: 2px solid white;
  z-index: 10;
  transition: all 100ms linear;
}

button:hover,
button:focus {
  box-shadow: 0 0 0 3px white;
  transition: all 100ms linear;
}

h1 {
  font-size: max(7rem, 2vw);
}

h1,
p {
  font-family: '3dumbregular';
  color: white;
  margin: 0px;
  position: relative;
  z-index: 1;
}

p {
  font-size: max(0.75rem, 1.5vw);
  font-family: 'Antic Slab', serif;
}

/* ---------------------------- IDs ---------------------------- */

/* .master-container > h1 {
  align-self: center;
} */

/* ---------------------------- classes ---------------------------- */

.attribution {
  font-size: 0.85em;
  color: #2f22ff;
  transition: all 500ms ease-in-out;
  padding-right: 10px;
}

.circle {
  background: rgb(242, 62, 46);
  background: linear-gradient(
    67deg,
    rgba(242, 62, 46, 1) 0%,
    rgba(242, 75, 50, 1) 20%,
    rgba(242, 130, 65, 1) 57%
  );
  border: 20px solid #f28241;
  border-radius: 90vw;
  height: 90vw;
  left: -45vw;
  position: absolute;
  top: -45vw;
  width: 90vw;
  z-index: 1;
}

.circle-gradient {
  height: 210px;
  width: 210px;
  border-image: linear-gradient(
    90deg,
    rgba(222, 37, 20, 1) 0%,
    rgba(83, 42, 140, 1) 34%,
    rgba(73, 39, 132, 1) 73%,
    rgba(1, 16, 75, 1) 100%
  );
  border-image-slice: 1;
}

.circle2 {
  display: flex;
  justify-content: center;
  width: 300px;
  height: 300px;
  margin: 50px auto;
  border-radius: 50%;
  animation: circle-animation 30s linear infinite;
  background: rgb(255, 175, 25);
  background: linear-gradient(
    90deg,
    rgba(242, 130, 65, 1) 10%,
    rgba(140, 50, 42, 1) 52%,
    rgba(93, 33, 176, 1) 87%
  );
}

.circle2 > p {
  align-self: center;
  transition: all 500ms ease-in-out;
  animation: fade-in-out 4000s linear infinite;
  font-family: '3dumbregular';
}

.container {
  margin-top: 14%;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 3em;
  transition: 500ms ease-in-out;
}

.joke-meta-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
  align-items: flex-end;
}

.joke-and-button-container {
  display: flex;
  flex-direction: column;
  align-items: self-end;
  grid-gap: 2rem;
  gap: 2rem;
}

.hover-effect {
  color: white;
}

.master-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2rem;
}

.p-and-button-container {
  display: flex;
}

.p-container {
  font-family: 'Antic Slab', serif;
  color: black;
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
}

.p-divider-a {
  max-width: 30vw;
  padding: 2vw 2vw 0vw 0vw;
  flex-shrink: 1;
}

.p-divider-b {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.punchline {
  color: #2f22f2;
  font-weight: 900;
  transition: all 500ms ease-in-out;
  margin-top: 1.5em;
  font-size: 1.75em;
  opacity: 0;
  transition: all 500ms ease-in;
}

.container > .punchline {
  transition: all 500ms ease-in;
}

.rating {
  font-size: 1.75em;
  color: #2f22f2;
  transition: all 500ms ease-in-out;
  min-width: fit-content;
  margin-right: 1em;
}

.second-circle {
  min-height: -moz-fit-content;
  min-height: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  position: absolute;
  left: 72%;
  bottom: 44%;
  z-index: -1;
}

.setup {
  font-family: '3dumbregular';
  font-weight: bold;
  color: white;
  font-size: 2rem;
}

/* ---------------------------- media queries ---------------------------- */
@media (max-width: 1100px) {
  .attribution {
    display: none;
  }

  .second-circle {
    display: none;
  }

  .p-and-button-container {
    display: flex;
    flex-direction: column;
  }

  .p-divider-a {
    max-width: 100%;
    padding: 0rem;
  }

  .container {
    margin-top: 0rem;
  }
}
@media (max-width: 800px) {
  body {
    font-size: 1.15em;
    padding: 2em;
  }

  h1 {
    font-size: 4rem;
  }
  .container {
    margin-top: 10%;
  }

  .joke-meta-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .p-container {
    flex-direction: column;
  }

  .p-divider-a {
    max-width: 100%;
  }

  .rating {
    font-size: 0.75em;
    color: #2f22f;
    transition: all 500ms ease-in-out;
    margin-bottom: 40px;
  }

  .setup {
    font-size: 1.25rem;
  }
}

@media (max-width: 450px) {
  h1 {
    font-size: 3rem;
  }
}

@keyframes circle-animation {
  0% {
    transform: rotate(0deg);
    border-radius: 25;
  }

  10% {
    transform: rotate(36deg);
    border-radius: 43% 57% 65% 35% / 47% 47% 53% 53%;
  }

  20% {
    transform: rotate(72deg);
    border-radius: 30% 70% 50% 50% / 59% 68% 32% 41%;
  }

  30% {
    transform: rotate(108deg);
    border-radius: 59% 41% 65% 35% / 47% 51% 49% 53%;
  }

  40% {
    transform: rotate(144deg);
    border-radius: 59% 41% 74% 26% / 36% 51% 49% 64%;
  }

  50% {
    transform: rotate(180deg);
    border-radius: 41% 59% 55% 45% / 52% 51% 49% 48%;
  }

  60% {
    transform: rotate(216deg);
    border-radius: 41% 59% 40% 60% / 52% 36% 64% 48%;
  }

  70% {
    transform: rotate(252deg);
    border-radius: 41% 59% 54% 46% / 52% 50% 50% 48%;
  }

  80% {
    ransform: rotate(288deg);
    border-radius: 62% 38% 54% 46% / 64% 50% 50% 36%;
  }

  90% {
    transform: rotate(324deg);
    border-radius: 54% 46% 54% 46% / 51% 50% 50% 49%;
  }

  100% {
    transform: rotate(360deg);
    border-radius: 50%;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
    color: white;
  }

  0% {
    opacity: 1;
    color: #f28241;
  }

  100% {
    opacity: 0;
    color: white;
  }
}
